// ==== THIS FILES HANDLES QUERIES FOR ALL PROJECTS CONTAINER ====
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  createProjectGoalObjective,
  createProjectGoalObjectiveKpi,
  editProjectGoalObjective,
  editProjectGoalObjectiveKpi,
  editSingleProject,
  fetchSingleProject,
} from "../../services/singleProject/singleProject";
import {
  createNewQuickLink,
  editProjectQuickLink,
  fetchAllQuickLinks,
} from "../../services/projects/ProjectQuickLink";
import {
  createGoalComment,
  createNewProjectGoal,
  createProjectGoalCommentResponses,
  editCommentProjectGoal,
  editProjectGoal,
  editProjectGoalCommentResponses,
  fetchAllGoalComments,
  fetchAllGoals,
  fetchAllProjectKPI,
  unvoteProjectGoal,
  upvoteProjectGoal,
} from "../../services/projects/ProjectGoalService";
import {
  createProjectAssumptions,
  editProjectAssumption,
  fetchProjectAssumptions,
  unvoteProjectAssumption,
  upvoteProjectAssumption,
} from "../../services/projects/ProjectAssumptionService";
import {
  createMasterNeed,
  fetchMasterNeedsCategories,
  updateMasterNeed,
} from "../../services/masterNeeds/masterNeedsService";
import { fetchProjectNeeds } from "../../services/projects/projectsService";
import {
  createNewProjectGallery,
  fetchAllProjectGallery,
} from "../../services/gallery";

// ----- QUERY DATA ---

const UseFetchsingleProject = (id) => {
  const fetchProject = async () => {
    const { result } = await fetchSingleProject(id);
    return result;
  };
  return useQuery({
    queryKey: ["project", id],
    queryFn: fetchProject,
  });
};

const UseFetchAllQuickLinks = (id, tab) => {
  const fetchQuickLinks = async () => {
    const { result } = await fetchAllQuickLinks(id);
    return result;
  };

  return useQuery({
    queryKey: ["quickLinks"],
    queryFn: fetchQuickLinks,
    enabled: tab === "quick" ? true : false,
  });
};

const UseFetchProjectGoals = (id, tab) => {
  const fetchProjectGoals = async () => {
    const { result } = await fetchAllGoals(id);
    return result;
  };

  return useQuery({
    queryKey: ["goals"],
    queryFn: fetchProjectGoals,
    enabled: tab === "goa" ? true : false,
  });
};

const UseFetchAllProjectKPI = (id, tab) => {
  const fetchProjectGoalsKPIs = async () => {
    const { result } = await fetchAllProjectKPI(id);
    return result;
  };

  return useQuery({
    queryKey: ["kpis"],
    queryFn: fetchProjectGoalsKPIs,
    enabled: tab === "goa" ? true : false,
  });
};

const UseFetchAllGoalComments = (projectID, goalID) => {
  const data = {
    project_id: projectID,
    project_goal_id: goalID,
  };
  const fetchProjectGoalComments = async () => {
    const { result } = await fetchAllGoalComments(data);
    return result;
  };

  return useQuery({
    queryKey: ["goalComments"],
    queryFn: fetchProjectGoalComments,
    enabled: goalID !== null ? true : false,
  });
};

const UseFetchProjectAssumptions = (id, tab) => {
  const fetchAssumptions = async () => {
    const { result } = await fetchProjectAssumptions(id);
    return result;
  };

  return useQuery({
    queryKey: ["assumptions"],
    queryFn: fetchAssumptions,
    enabled: tab === "ass" ? true : false,
  });
};

// ---- NEEDS ----
const UseFetchMasterNeedCategories = (tab) => {
  const fetchCategories = async () => {
    const { result } = await fetchMasterNeedsCategories();
    return result;
  };

  return useQuery({
    queryKey: ["inventory_categories"],
    queryFn: fetchCategories,
    enabled: tab === "nee" ? true : false,
  });
};

const UseFetchProjectNeeds = (tab, id) => {
  const fetchNeeds = async () => {
    const { result } = await fetchProjectNeeds(id);
    return result;
  };

  return useQuery({
    queryKey: ["needs"],
    queryFn: fetchNeeds,
    enabled: tab === "nee" ? true : false,
  });
};

const UseFetchProjectGallery = (tab, id) => {
  const fetchGallery = async () => {
    const { result } = await fetchAllProjectGallery(id);
    return result;
  };

  return useQuery({
    queryKey: ["gallery"],
    queryFn: fetchGallery,
    enabled: tab === "gal" ? true : false,
  });
};

// ---- MUTATE DATA ----

const UseEditProject = (fn) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (projectData) => editSingleProject(projectData),
    onSuccess: (backendResponse, variable) => {
      queryClient.invalidateQueries({
        queryKey: ["project", `${variable.id}`],
      });
      fn();
    },
  });
};

const UseCreateNewQuickLink = (fn) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => createNewQuickLink(data),

    onMutate: async (newQuickLink) => {
      await queryClient.cancelQueries("quickLinks");
      const previousQuickLinks = queryClient.getQueryData(["quickLinks"]);
      queryClient.setQueryData(["quickLinks"], (oldData) => [
        ...oldData,
        newQuickLink.project_quick_link,
      ]);

      fn();
      return { previousQuickLinks };
    },

    onError: (err, newTodo, context) => {
      queryClient.setQueryData(["quickLinks"], context.previousQuickLinks);
    },

    onSuccess: (backendResponse, variable) => {
      queryClient.invalidateQueries({
        queryKey: ["quickLinks"],
      });
    },
  });
};

const UseEditQuickLink = (fn) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => editProjectQuickLink(data),

    onMutate: async (editedQuickLink) => {
      fn();
      await queryClient.cancelQueries("quickLinks");
      const previousQuickLinks = queryClient.getQueryData(["quickLinks"]);

      queryClient.setQueryData(["quickLinks"], (oldData) =>
        oldData.map((link) =>
          link.id === editedQuickLink.id
            ? editedQuickLink.quikData.project_quick_link
            : link
        )
      );
      return { previousQuickLinks };
    },

    onError: (err, newTodo, context) => {
      queryClient.setQueryData(["quickLinks"], context.previousQuickLinks);
    },

    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["quickLinks"],
      });
    },
  });
};

const UseCreateNewProjectGoal = (fn) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => createNewProjectGoal(data),

    onSuccess: (backendResponse, variable) => {
      queryClient.invalidateQueries({
        queryKey: ["goals"],
      });

      fn();
    },
  });
};

const UseEditProjectGoal = (fn) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => editProjectGoal(data),

    onSuccess: (backendResponse, variable) => {
      queryClient.invalidateQueries({
        queryKey: ["goals"],
      });

      fn();
    },
  });
};

const UseUpvoteProjectGoal = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => upvoteProjectGoal(data),

    onMutate: async (upvoteGoalData) => {
      await queryClient.cancelQueries("goals");
      const { upvote } = upvoteGoalData;
      const previousGoals = queryClient.getQueryData(["goals"]);

      queryClient.setQueryData(["goals"], (oldData) =>
        oldData.map((goal) =>
          goal.id === upvote.upvoteable_id
            ? {
                ...goal,
                likes: { upvoted: true, counter: goal.likes.counter + 1 },
              }
            : goal
        )
      );
      return { previousGoals };
    },

    onError: (error, variables, context) => {
      queryClient.setQueryData("goals", context.previousGoals);
    },

    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["goals"],
      });
    },
  });
};

const UseUnVoteProjectGoal = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => unvoteProjectGoal(data),

    onMutate: async (unvoteData) => {
      await queryClient.cancelQueries("goals");
      const { upvoteable_id } = unvoteData;
      const previousGoals = queryClient.getQueryData(["goals"]);

      queryClient.setQueryData(["goals"], (oldData) =>
        oldData.map((goal) =>
          goal.id === upvoteable_id
            ? {
                ...goal,
                likes: { upvoted: false, counter: goal.likes.counter - 1 },
              }
            : goal
        )
      );
      return { previousGoals };
    },

    onError: (error, variables, context) => {
      queryClient.setQueryData("goals", context.previousGoals);
    },

    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["goals"],
      });
    },
  });
};

const UseCreateNewProjectObjective = (fn) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => createProjectGoalObjective(data),

    onMutate: async (objectiveData) => {
      fn();
      await queryClient.cancelQueries("goals");
      const previousGoals = queryClient.getQueryData(["goals"]);
      const { goal_id } = objectiveData;

      queryClient.setQueryData(["goals"], (oldData) =>
        oldData.map((goal) =>
          goal.id === goal_id
            ? {
                ...goal,
                project_goal_objectives: [
                  ...goal.project_goal_objectives,
                  { name: objectiveData.name },
                ],
              }
            : goal
        )
      );

      return { previousGoals };
    },

    onError: (error, variables, context) => {
      queryClient.setQueryData("goals", context.previousGoals);
    },

    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["goals"],
      });
    },
  });
};

const UseEditProjectObjective = (fn) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => editProjectGoalObjective(data),

    onSuccess: (backendResponse, variable) => {
      queryClient.invalidateQueries({
        queryKey: ["goals"],
      });
      fn();
    },
  });
};

const UseCreateProjectKPI = (fn) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => createProjectGoalObjectiveKpi(data),

    onMutate: async (kpiData) => {
      await queryClient.cancelQueries("kpis");
      const previousKpis = queryClient.getQueryData(["kpis"]);

      queryClient.setQueryData(["kpis"], (oldData) =>
        oldData.map((kpi) =>
          kpi.id === kpiData.objective_id
            ? {
                ...kpi,
                project_goal_objective_kpis: [
                  ...kpi.project_goal_objective_kpis,
                  {
                    name: kpiData.name,
                    project_id: kpiData.project_id,
                    project_goal_objective_id: kpiData.objective_id,
                  },
                ],
              }
            : kpi
        )
      );
      fn();
      return { previousKpis };
    },

    onError: (error, variables, context) => {
      queryClient.setQueryData("kpis", context.previousKpis);
    },

    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["kpis"],
      });
    },
  });
};

const UseEditProjectKPI = (fn) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => editProjectGoalObjectiveKpi(data),

    onSuccess: (backendResponse, variable) => {
      queryClient.invalidateQueries({
        queryKey: ["kpis"],
      });
      fn();
    },
  });
};

const UseCreateProjectGoalComment = (fn) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => createGoalComment(data),

    onSuccess: (backendResponse, variable) => {
      queryClient.invalidateQueries({
        queryKey: ["goalComments"],
      });

      fn();

      queryClient.invalidateQueries({
        queryKey: ["goals"],
      });
    },
  });
};

const UseEditProjectGoalComment = (fn) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => editCommentProjectGoal(data),

    onSuccess: (backendResponse, variable) => {
      queryClient.invalidateQueries({
        queryKey: ["goalComments"],
      });
      fn();
    },
  });
};

const UseUpvoteOfGoalComment = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => upvoteProjectAssumption(data),
    onMutate: async (comment) => {
      await queryClient.cancelQueries("goalComments");
      const previousGoalComments = queryClient.getQueryData(["goalComments"]);
      const { upvote } = comment;

      queryClient.setQueryData(["goalComments"], (oldData) =>
        oldData.map((comment) =>
          comment.id === upvote.upvoteable_id
            ? {
                ...comment,
                likes: {
                  upvoted: true,
                  counter: comment.likes.counter + 1,
                },
              }
            : comment
        )
      );

      return { previousGoalComments };
    },

    onError: (error, variables, context) => {
      queryClient.setQueryData("goalComments", context.previousGoalComments);
    },

    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["goalComments"],
      });
    },
  });
};

const UseunVoteOfGoalComment = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => unvoteProjectAssumption(data),
    onMutate: async (comment) => {
      await queryClient.cancelQueries("goalComments");
      const previousGoalComments = queryClient.getQueryData(["goalComments"]);
      const { upvoteable_id } = comment;

      queryClient.setQueryData(["goalComments"], (oldData) =>
        oldData.map((comment) =>
          comment.id === upvoteable_id
            ? {
                ...comment,
                likes: {
                  upvoted: false,
                  counter: comment.likes.counter - 1,
                },
              }
            : comment
        )
      );

      return { previousGoalComments };
    },

    onError: (error, variables, context) => {
      queryClient.setQueryData("goalComments", context.previousGoalComments);
    },

    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["goalComments"],
      });
    },
  });
};

const UseCreateOfGoalCommentReponses = (fn) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => createProjectGoalCommentResponses(data),

    onSuccess: (backendResponse, variable) => {
      queryClient.invalidateQueries({
        queryKey: ["goalComments"],
      });

      fn();
    },
  });
};

const UseEditOfGoalCommentReponses = (fn) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => editProjectGoalCommentResponses(data),

    onSuccess: (backendResponse, variable) => {
      queryClient.invalidateQueries({
        queryKey: ["goalComments"],
      });

      fn();
    },
  });
};

const UseUpvoteOfCommentResponse = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => upvoteProjectAssumption(data),

    onSuccess: (backendResponse, variable) => {
      queryClient.invalidateQueries({
        queryKey: ["goalComments"],
      });
    },
  });
};

const UseUnVoteOfCommentResponse = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => unvoteProjectAssumption(data),

    onSuccess: (backendResponse, variable) => {
      queryClient.invalidateQueries({
        queryKey: ["goalComments"],
      });
    },
  });
};

const UseCreateNewProjectAssumption = (fn) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => createProjectAssumptions(data),

    onSuccess: (backendResponse, variable) => {
      fn();
      queryClient.invalidateQueries({
        queryKey: ["assumptions"],
      });
    },
  });
};

const UseEditProjectAssumption = (fn) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => editProjectAssumption(data),

    onSuccess: (backendResponse, variable) => {
      fn();
      queryClient.invalidateQueries({
        queryKey: ["assumptions"],
      });
    },
  });
};

const UseUpvoteProjectAssumption = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => upvoteProjectAssumption(data),

    onMutate: async (assumption) => {
      await queryClient.cancelQueries("assumptions");
      const previousProjectAssumption = queryClient.getQueryData([
        "assumptions",
      ]);

      const { upvote } = assumption;
      const { upvoteable_id } = upvote;

      queryClient.setQueryData(["assumptions"], (oldData) =>
        oldData.map((assumption) =>
          assumption.id === upvoteable_id
            ? {
                ...assumption,
                likes: {
                  upvoted: true,
                  counter: assumption.likes.counter + 1,
                },
              }
            : assumption
        )
      );
      return { previousProjectAssumption };
    },

    onError: (error, variables, context) => {
      queryClient.setQueryData(
        "assumptions",
        context.previousProjectAssumption
      );
    },

    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["assumptions"],
      });
    },
  });
};

const UseUnvoteProjectAssumption = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => unvoteProjectAssumption(data),

    onMutate: async (assumption) => {
      await queryClient.cancelQueries("assumptions");
      const previousProjectAssumption = queryClient.getQueryData([
        "assumptions",
      ]);

      const { upvoteable_id } = assumption;
      queryClient.setQueryData(["assumptions"], (oldData) =>
        oldData.map((assumption) =>
          assumption.id === upvoteable_id
            ? {
                ...assumption,
                likes: {
                  upvoted: false,
                  counter: assumption.likes.counter - 1,
                },
              }
            : assumption
        )
      );
      return { previousProjectAssumption };
    },

    onError: (error, variables, context) => {
      queryClient.setQueryData(
        "assumptions",
        context.previousProjectAssumption
      );
    },

    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["assumptions"],
      });
    },
  });
};

// --- NEEDS ---
const UseCreateNewNeed = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => createMasterNeed(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["needs"],
      });
    },
  });
};

const UseEditProjectNeed = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => updateMasterNeed(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["needs"],
      });
    },
  });
};

// ---- GALLERY ----

const UseCreateNewProjectGallery = (fn) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => createNewProjectGallery(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["gallery"],
      });
      fn();
    },
  });
};

export {
  UseFetchsingleProject,
  UseEditProject,
  UseFetchAllQuickLinks,
  UseCreateNewQuickLink,
  UseEditQuickLink,
  UseFetchProjectGoals,
  UseCreateNewProjectGoal,
  UseEditProjectGoal,
  UseUpvoteProjectGoal,
  UseUnVoteProjectGoal,
  UseCreateNewProjectObjective,
  UseEditProjectObjective,
  UseFetchAllProjectKPI,
  UseCreateProjectKPI,
  UseEditProjectKPI,
  UseCreateProjectGoalComment,
  UseEditProjectGoalComment,
  UseFetchAllGoalComments,
  UseUpvoteOfGoalComment,
  UseunVoteOfGoalComment,
  UseCreateOfGoalCommentReponses,
  UseEditOfGoalCommentReponses,
  UseUpvoteOfCommentResponse,
  UseUnVoteOfCommentResponse,
  UseFetchProjectAssumptions,
  UseCreateNewProjectAssumption,
  UseEditProjectAssumption,
  UseUpvoteProjectAssumption,
  UseUnvoteProjectAssumption,
  UseFetchMasterNeedCategories,
  UseFetchProjectNeeds,
  UseCreateNewNeed,
  UseEditProjectNeed,
  UseFetchProjectGallery,
  UseCreateNewProjectGallery,
};
